import React, { useEffect, useState } from "react";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./applicationInsights";
import { ProvideAuth } from "hooks/useAuth";
import { NotificationProvider } from "context/NotificationProvider";
import { AppDataProvider } from "context/AppDataProvider";
import { NavProvider } from "context/NavProvider";
import Notifications from "components/Notifications";
import AppUpdateNotification from "components/AppUpdateNotification";
import { ModalProvider } from "context/ModalProvider";
import SidebarProvider from "context/SidebarProvider";
import Error500 from "pages/Error500";
import BackgroundSyncNotification from "components/BackgroundSyncNotification";
import CustomRoutes from "routes/CustomRoutes";

const version = require("../package.json").version;
export const AppVersion = `v${version}`;

export default function App() {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(undefined);

  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdateAvailable",
      handleServiceWorkerUpdateAvailable
    );

    return () =>
      document.removeEventListener(
        "serviceWorkerUpdateAvailable",
        handleServiceWorkerUpdateAvailable
      );
  }, []);

  const handleServiceWorkerUpdateAvailable = (ev) => {
    setWaitingWorker(ev.detail.waiting);
    setUpdateAvailable(true);
  };

  const handleUpdateApp = () => {
    // Call skip waiting to automatically update app.
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setUpdateAvailable(false);
    waitingWorker.addEventListener("statechange", (e) => {
      // Wait for new service worker to become active
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  };

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <Error500 />}
        appInsights={reactPlugin}
      >
        <AppDataProvider>
          <SidebarProvider>
            <NavProvider>
              <NotificationProvider>
                <ModalProvider>
                  <ProvideAuth>
                    <Notifications />
                    <AppUpdateNotification
                      show={updateAvailable}
                      onClick={handleUpdateApp}
                    />
                    <BackgroundSyncNotification />

                    <CustomRoutes />
                  </ProvideAuth>
                </ModalProvider>
              </NotificationProvider>
            </NavProvider>
          </SidebarProvider>
        </AppDataProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}



